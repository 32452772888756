export const formatTimestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp)

  return date
    .toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(",", " at")
}

export type ISOString = string // "2023-11-07T12:06:17.676Z"
